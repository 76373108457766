import {get, patch, post, put} from "@/lib/api/apiHelper";

export function getMe() {
  return get({path: '/me'});
}

export function updateMe(payload) {
  return patch({path: '/me', payload});
}

export function createSubscription(payload) {
  return post({path: '/subscriptions', payload});
}

export function getSubscription(id) {
  return get({path: `/subscriptions/${id}`});
}

export function updateSubscriptionShipping({id,payload}) {
  return put({path: `/subscriptions/${id}/shipping`, payload});
}

export function updateSubscriptionPaymentCard({id,token}) {
  return put({path: `/subscriptions/${id}/payment-card/${token}`});
}

export function cancelSubscription(id) {
  return put({path: `/subscriptions/${id}/cancel`});
}

export function restoreSubscription(id) {
  return put({path: `/subscriptions/${id}/restore`});
}

export function checkPromoCode(code) {
  return get({path: `/promo-codes/${code}`});
}

export function sendContactForm(payload) {
  return post({path: '/contact-us', payload})
}

export function addressSearch(query) {
  return get({path: '/addresses/nz', queryParams: {query}})
}

export function getAddressDetails(id) {
  return get({path: `/addresses/nz/${id}`})
}

export function submitForm({id, payload}) {
  return post({path: `/forms/${id}`, payload})
}

export function getInvoice(id) {
  return get({path: `/invoices/${id}`});
}

export function sendPasswordResetEmail(queryParams) {
  return post({path: '/email-actions/password-reset', queryParams})
}

export function sendLoginEmail(queryParams) {
  return post({path: '/email-actions/login', queryParams})
}

export function createContact(payload) {
  return put({path: '/contacts', payload})
}

export function addFreeStoryId(storyId) {
  return put({path: `/me/free-story-ids/${storyId}`});
}

export function logReadStoryEvent(storyId) {
  return put({path: `/me/read-stories/${storyId}`});
}

export function getSearchResults(queryParams) {
  return get({path: '/search/stories', queryParams});
}
