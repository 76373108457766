import {useEffect, useState} from "react";

export default function useLocalStorage(key, initialValue) {

  // This hook will use the initial value on first render. Then when the component is mounted in the browser,
  // it will attempt to load the value stored in local storage.

  const [storedValue, setStoredValue] = useState(initialValue);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // If there's a value, then set state to parsed json
      if (item !== null) {
        setStoredValue(JSON.parse(item));
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoaded(true);
    }
  }, []);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = value => {
    // Allow value to be a function so we have same API as useState
    if (value instanceof Function) {
      setStoredValue(prevState => {
        const newValue = value(prevState);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(newValue));
        return newValue;
      })
    } else {
      // Save state
      setStoredValue(value);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  };

  const clearValue = () => {
    window.localStorage.removeItem(key);
  };

  return [storedValue, setValue, clearValue, loaded];
}
