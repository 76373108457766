import React, {useEffect, useState} from 'react';
import firebase from "@/lib/auth/firebase";
import {getMe} from "@/lib/api/api";
import useLocalStorage from "@/lib/utils/useLocalStorage";

export const Context = React.createContext({});

export const useContext = () => React.useContext(Context);

let loggingOut = false;

export async function doLogout() {
  loggingOut = true;
  await firebase.auth().signOut();
  loggingOut = false;
}

export function Provider({children, pageProps}) {
  const [error, setError] = useState();
  const [dialog, setDialog] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [user, setUser] = useState(null);
  const [auth, setAuth] = useState(null);
  const [authLoaded, setAuthLoaded] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState({});
  const [authRedirectError, setAuthRedirectError] = useState(null);
  const [cart, setCart, clearCart, cartLoaded] = useLocalStorage('cart', {});
  const [banners, setBanners, clearBanners, bannersLoaded] = useLocalStorage('banners', {});
  const [popUps, setPopUps, clearPopUps, popUpsLoaded] = useLocalStorage('popups', {});
  const [anonStories, setAnonStories] = useLocalStorage('anonStories');
  const [searchFunction, setSearchFunction] = useState(false);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      // console.log('onAuthStateChanged', user);
      setAuth({user});
      setAuthLoaded(true);
      if (user && !loggingOut) {
        setAuthRedirectError(null);
        const me = await getMe();
        setUser(me);
        // Send Google Analytics request on login or auth state change.
        if (site.slug === "woman") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event' : 'login',
            'userId' : me.id,
          });
        }
      } else {
        setUser(null);
      }
    });
    handleRedirectResult().catch(console.log);
  }, []);

  const handleRedirectResult = async () => {
    try {
      await firebase.auth().getRedirectResult();
    } catch (error) {
      setAuthRedirectError(error);
    }
  }

  const {
    config,
    site,
    campaigns,
    categories,
    products,
  } = pageProps;

  const state = {
    config,
    site,
    campaigns,
    categories,
    products,
    auth,
    authLoaded,
    authRedirectError,
    user,
    setUser,
    subscriptionDetails,
    setSubscriptionDetails,
    dialog,
    setDialog,
    error,
    setError,
    cart,
    setCart,
    clearCart,
    cartLoaded,
    banners,
    setBanners,
    bannersLoaded,
    popUps,
    setPopUps,
    popUpsLoaded,
    anonStories,
    setAnonStories,
    searchFunction,
    setSearchFunction,
    form: {
      submitting,
      setSubmitting,
    },
  };

  return (
      <Context.Provider value={state}>
        {children}
      </Context.Provider>
  )
}
