import {isEmpty} from "lodash";
import getConfig from "next/config";

const {site} = getConfig().publicRuntimeConfig;

export const SITE_URL = `https://${site}magazine.co.nz`;

export function getRandom(i) {
  return Math.floor(Math.random() * i);
}

export function getRandomNode(nodes) {
  return nodes?.length ? nodes[getRandom(nodes.length)] : null;
}

export function isProductGift(product) {
  return product && product.metadata.format.toLowerCase() === "print";
}

export function waitTimeout(timeout) {
  return new Promise((res) => setTimeout(res, timeout));
}

export function formatAddress(address) {
  return [
    address.line1,
    address.line2,
    `${address.city} ${address.postalCode}`,
  ].filter(line => line).join(', ');
}

export function objToQuery(obj) {
  if (!obj || isEmpty(obj)) {
    return '';
  }
  const str = [];
  for (const p in obj) {
    if (obj.hasOwnProperty(p) && obj[p] != undefined) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }
  return str.length ? `?${str.join("&")}` : '';
}

export function queryToObj(query) {
  const params = new URLSearchParams(query);
  const obj = {};
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }
  return obj;
}

export function containsTruthy(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      return true;
    }
  }
}

export function removeUndefined(obj) {
  if (!obj) {
    return obj;
  }
  const result = {...obj};
  Object.keys(result).forEach(key => result[key] === undefined && delete result[key]);
  return result;
}
