import getConfig from "next/config";
import firebase from "@/lib/auth/firebase";
import {objToQuery} from "@/lib/utils/utils";

const { site,apiUrl } = getConfig().publicRuntimeConfig;
const firebaseAuth = firebase.auth();

async function req({
                     method='GET',
                     path='/',
                     queryParams,
                     body = null,
                     headers={}
                   }) {
  const query = objToQuery(queryParams);
  const url = `${apiUrl}${path}${query}`;
  let authorization;
  if(firebaseAuth.currentUser) {
    const token = await firebaseAuth.currentUser.getIdToken();
    authorization = `Bearer ${token}`;
  }
  const res = await fetch(url, {
    method,
    headers: {
      Authorization: authorization,
      'x-srp-site': site,
      ...headers,
    },
    body,
  });
  const responseBody = await res.text();
  const responseObject = responseBody ? JSON.parse(responseBody) : {};
  if(!res.ok) {
    const error = new Error(`Failed to fetch content. ${res.status} ${res.statusText}`);
    error.response = responseObject;
    throw error;
  }
  return responseObject;
}

export function get({path,queryParams,headers={}}) {
  return req({
    path,
    queryParams,
    headers,
  });
}

export function patch({path,queryParams,payload,headers={}}) {
  return req({
    method: 'PATCH',
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    path,
    queryParams,
  });
}

export function post({path,queryParams,payload,headers={}}) {
  return req({
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    path,
    queryParams,
  });
}

export function put({path,queryParams,payload,headers={}}) {
  return req({
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'content-type': 'application/json',
      ...headers,
    },
    path,
    queryParams,
  });
}
